<template>
	<div>
		<PreviousButton />
		<div class="signup">
			<Loading v-if="local.loading" />
			<h1 class="title signup-title">
				{{ $t('signup.title') }}
			</h1>
			<p class="text login-info">
				{{ $t('login.info')[directSubscription] || $t('login.info.general') }}
			</p>
			<router-link
				:to="{ name: 'login', params: $route.params, query: $route.query }"
				class="signup-link"
			>
				{{ $t('signup.alreadyAccount') }}
			</router-link>
			<form
				action=""
				class="signup-form"
				novalidate="true"
				autocomplete="off"
				@submit.prevent="validateBeforeSubmit"
			>
				<div class="signup-fields">
					<div class="signup-fields-block">
						<div class="input-box signup-input">
							<label class="signup-label">
								<span>{{ $t('profile.firstNameLabel') }}*</span>
							</label>
							<input
								v-model="profile.firstName"
								v-validate="{ required: true, regex: '^[^0-9]+$' }"
								:placeholder="$t('profile.firstNameLabel')"
								:class="{ error: errors.has('firstName') }"
								:data-vv-as="$t('profile.firstNameLabel')"
								name="firstName"
								type="text"
								class="input-field"
							/>
							<p v-show="errors.has('firstName')" class="input-error">
								{{ errors.first('firstName') }}
							</p>
						</div>
						<div class="input-box signup-input">
							<label class="signup-label">
								<span>{{ $t('profile.lastNameLabel') }}*</span>
							</label>
							<input
								v-model="profile.lastName"
								v-validate="{ required: true, regex: '^[^0-9]+$' }"
								:placeholder="$t('profile.lastNameLabel')"
								:class="{ error: errors.has('lastName') }"
								:data-vv-as="$t('profile.lastNameLabel')"
								name="lastName"
								type="text"
								class="input-field"
							/>
							<p v-show="errors.has('lastName')" class="input-error">
								{{ errors.first('lastName') }}
							</p>
						</div>
						<div class="input-box signup-input">
							<label class="signup-label">
								<span>{{ $t('profile.emailLabel') }}*</span>
							</label>
							<input
								v-model="profile.email"
								v-validate="'required|email'"
								:placeholder="$t('profile.emailLabel')"
								:class="{ error: errors.has('email') }"
								:data-vv-as="$t('profile.emailLabel')"
								name="email"
								type="email"
								class="input-field"
							/>
							<p v-show="errors.has('email')" class="input-error">
								{{ errors.first('email') }}
							</p>
						</div>
						<!-- </div>
					<div class="signup-fields-block"> -->
						<div class="input-box signup-input">
							<label class="signup-label">
								<span>{{ $t('profile.passwordLabelSignup') }}*</span>
							</label>
							<div class="input-box">
								<input
									ref="signup"
									v-model="profile.password"
									v-validate="'required|min:6'"
									:placeholder="$t('profile.passwordLabelSignup')"
									:class="{ error: errors.has('password') }"
									:data-vv-as="$t('profile.passwordLabelSignup')"
									name="password"
									type="password"
									class="input-field input-password"
								/>
								<div class="input-reveal-button" @click="toggleReveal">
									<div :class="{ revealed: local.reveal }" class="input-reveal-button-line" />
								</div>
								<p v-show="errors.has('password')" class="input-error">
									{{ errors.first('password') }}
								</p>
							</div>
						</div>
						<div class="input-box signup-input">
							<label class="signup-label">
								<span>{{ $t('signup.telephoneLabel') }}*</span>
							</label>
							<vue-tel-input
								v-model="profile.phoneNumber"
								:preferredCountries="['be', 'nl', 'fr']"
								:class="{ error: !!local.errors.phone }"
								:inputOptions="localizedData.options"
								class="signup-phone-box input-field"
								required
								@input="onInputPhone"
							/>
							<p v-show="!!local.errors.phone" class="input-error">
								{{ local.errors.phone }}
							</p>
						</div>
						<div class="input-box signup-input">
							<label class="signup-label">
								<span>{{ $t('signup.languageLabel') }}</span>
							</label>
							<div class="signup-radio">
								<label
									v-for="(language, index) in SignupLanguages"
									:key="index"
									class="signup-radio-group"
								>
									<input
										class="signup-radio-group-input"
										type="radio"
										v-model="profile.language"
										:value="language"
										:checked="language.value === findCurrentLanguage().value"
										@change="selectOption('language', language)"
									/>
									<span class="custom-radio"></span>
									<label :for="language.key" class="signup-language-radio-label">
										<img :src="require(`../../assets/images/flags/${language.icon}`)" />
										{{ language.value }}
									</label>
								</label>
							</div>
							<p v-if="local.errors.language" class="input-error">
								{{ local.errors.language }}
							</p>
						</div>

						<!-- <div class="input-box signup-input">
							<label class="signup-label">
								<span>{{ $t('signup.mobibLabel') }}</span>
							</label> -->
						<!-- <MobibInput
								:mobib.sync="profile.mobib"
								:mobibPresent="false"
								:message="$t('profile.mobibInfo')"
								:class = "{ error: errors.has('mobib') }"
								errorTag="mobib"
								autocomplete="off"
								class="input-box profile-create-account-form-mobib"
							/>
							<p v-show="errors.has('mobib')" class="input-error">
								{{ errors.first('mobib') }}
							</p> -->
						<!-- <div class="profile-input-action-box">
								<cleave
									ref="mobibField"
									v-model="profile.mobib"
									v-validate="{ regex: '^(?=[0-9A-Fa-f]*$)(?:.{16}|.{18})$' }"
									:placeholder="$t('profile.mobibLabel')"
									:data-vv-as="$t('profile.mobibLabel')"
									:options="cleaveOptions"
									name="mobib"
									type="text"
									class="input-field profile-input-field"
								/>
							</div>
							<p v-show="errors.has('profile-form.mobib')" class="input-error">
								{{ errors.first('profile-form.mobib') }}
							</p> -->
						<!-- <cleave
								v-model="profile.mobib"
								v-validate="{ regex: '^(?=[0-9A-Fa-f]*$)(?:.{16}|.{18})$' }"
								:placeholder="$t('profile.mobibLabel')"
								:class="{ error: errors.has('mobib') }"
								:data-vv-as="$t('profile.mobibLabel')"
								name="mobib"
								type="text"
								class="input-field"
								:options="cleaveOptions"
								/> -->
						<!-- maxlength="18" -->
						<!-- <p v-show="errors.has('mobib')" class="input-error">
								{{ errors.first('mobib') }}
							</p> -->

						<!-- </div> -->
					</div>
				</div>
				<div class="signup-checkboxes">
					<Checkbox
						v-model="local.termsOfCondition"
						:class="{ error: local.errors.termsOfCondition }"
						name="termsOfCondition"
						@input="clearCheckboxError('termsOfCondition')"
					>
						<span slot="label" class="checkbox-text text signup-checkbox-text">
							{{ $t('signup.acceptLabel') }}
							<a
								:href="$t('general.navigationLinks.terms')"
								target="_blank"
								class="signup-checkbox-text-link"
								>{{ $t('general.navigation.terms') }}</a
							>
						</span>
					</Checkbox>
					<p v-show="local.errors.termsOfCondition" class="input-error">
						{{ $t('error.acceptTerms') }}
					</p>
					<Checkbox
						v-model="local.privacyPolicy"
						:class="{ error: local.errors.privacyPolicy }"
						name="pivacy"
						@input="clearCheckboxError('privacyPolicy')"
					>
						<span slot="label" class="checkbox-text text signup-checkbox-text">
							{{ $t('signup.acceptLabel') }}
							<a
								:href="$t('general.navigationLinks.privacy')"
								target="_blank"
								class="signup-checkbox-text-link"
								>{{ $t('general.privacyLabel') }}</a
							>
						</span>
					</Checkbox>
					<p v-show="local.errors.privacyPolicy" class="input-error">
						{{ $t('error.acceptPrivacy') }}
					</p>
					<!-- <Checkbox v-model="profile.newsletter" name="newsletter">
						<span slot="label" class="checkbox-text text">
							{{ $t('signup.newsletterLabel') }}
						</span>
					</Checkbox> -->
				</div>
				<button :disabled="errors.any()" class="button red signup-button" type="submit">
					{{ $t('signup.createButtonLabel') }}
				</button>
				<!-- <router-link
					:to="{ name: 'login', params: $route.params, query: $route.query }"
					class="link signup-link"
				>
					{{ $t('signup.alreadyAccount') }}
				</router-link> -->
				<p v-if="signupError.message" class="signup-error">
					{{ signupError.message }}
				</p>
			</form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { logger } from '../../logger'
import Dropdown from '@/components/form/Dropdown.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import Loading from '@/components/Loading.vue'
import HasSubscriptionInfo from '@/mixins/has-subscription-info'
import { SignupLanguages } from '@/config'
import MobibInput from '@/components/form/MobibInput.vue'
import PreviousButton from '@/components/PreviousButton.vue'

export default {
	name: 'Signup',
	components: {
		Dropdown,
		Checkbox,
		Loading,
		MobibInput,
		PreviousButton,
	},
	mixins: [HasSubscriptionInfo],
	data() {
		return {
			SignupLanguages,
			profile: {
				language: {
					value: 'Nederlands',
					key: 'nl',
					icon: 'icon_flag_nl.svg',
				},
				firstName: '',
				lastName: '',
				email: '',
				password: '',
				phoneNumber: '',
				mobib: '',
			},
			local: {
				reveal: false,
				loading: false,
				phoneNumber: '',
				errors: {
					firstName: '',
					lastName: '',
					email: '',
					password: '',
					phone: '',
					language: '',
					termsOfCondition: '',
					privacyPolicy: '',
					mobib: '',
				},
			},
			options: { placeholder: this.$t('profile.phoneLabel') },
		}
	},
	computed: {
		localizedData() {
			return {
				options: { placeholder: this.$t('profile.phoneLabel') },
			}
		},
		...mapState({
			signupError: (state) => {
				return { type: state.alert.type, message: state.alert.message }
			},
		}),
		cleaveOptions() {
			if (this.profile.mobib && this.profile.mobib.length > 1 && this.profile.mobib[1] === '0') {
				// eslint-disable-next-line no-magic-numbers
				return { blocks: [4, 4, 4, 4, 2], uppercase: true }
			} else {
				// eslint-disable-next-line no-magic-numbers
				return { blocks: [2, 4, 4, 4, 4], uppercase: true }
			}
		},
	},
	mounted() {
		this.$validator.pause()
	},
	updated() {},
	beforeDestroy() {
		this.clearGeneralError()
	},
	methods: {
		...mapActions({
			createUser: 'authentication/createUser',
			clearSignupError: 'alert/clear',
		}),
		async doCreateUser() {
			this.local.loading = true
			console.log('this.profile', this.profile)

			try {
				await this.createUser(this.profile)
				this.goAfterAuth()
			} catch (e) {
				logger.error('Error creating user', e)
			} finally {
				this.local.loading = false
			}
		},
		toggleReveal() {
			this.local.reveal = !this.local.reveal

			if (this.local.reveal) {
				this.$refs.signup.setAttribute('type', 'text')
			} else {
				this.$refs.signup.setAttribute('type', 'password')
			}
		},
		selectOption(field, option) {
			this.local.errors[field] = ''
			this.profile[field] = option
		},
		findCurrentLanguage() {
			if (typeof this.profile.language === 'string') {
				return this.language.find((l) => l.key === this.profile.language) || {}
			} else {
				return this.profile.language || {}
			}
		},
		hasLocalErrors() {
			return (
				Object.keys(this.local.errors).some((key) => this.local.errors[key]) ||
				(this.profile.phoneNumber || '').length < 1
			)
		},
		validateInputFields() {
			this.local.errors.language = this.profile.language.value ? '' : this.$t('error.emptyLanguage')
			this.local.errors.termsOfCondition = this.local.termsOfCondition ? '' : 'error'
			this.local.errors.privacyPolicy = this.local.privacyPolicy ? '' : 'error'
			this.local.errors.phone = this.profile.phoneNumber ? '' : this.$t('error.emptyPhoneNumber')
			// this.local.errors.mobib = this.profile.mobib.length >0  ? '' : 'error'
		},
		clearCheckboxError(field) {
			if (this.local.errors[field]) {
				this.local.errors[field] = ''
			}
		},
		clearGeneralError() {
			if (this.signupError.message) {
				this.clearSignupError()
			}
		},
		clearErrors(e) {
			this.clearGeneralError()

			if (this.local.errors[e.target.name]) {
				this.local.errors[e.target.name] = ''
			}
		},
		validateBeforeSubmit() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			this.$validator.validateAll().then((result) => {
				this.validateInputFields()

				if (result && !this.hasLocalErrors()) {
					// We store the phoneNumber in a local val on the 'onInputPhone' event because of a bug with the vue-phone-number-input component
					// When we copy-past a phone number in the field when it was already filled-in the country-code was not added.
					// This way, we make sure that the phone number is always correct.
					this.profile.phoneNumber = this.local.phoneNumber

					this.doCreateUser()
				}
			})
		},
		onInputPhone(value, phoneObject) {
			this.local.phoneNumber = phoneObject.number

			const empty = (value || '').length < 1

			this.local.errors.phone = empty || phoneObject.valid ? '' : this.$t('error.notValidPhone')
		},
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/signup';
@import '../../assets/scss/components/profile/createAccount';
</style>
