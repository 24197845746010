import APIClient from '../api/apiClient'

const DepositServices = {
	//Service to refund new key
	refundNewKeyForSubscription: (payload) => {
		payload.itemId = payload.itemId.toString()
		return APIClient.post(`/deposits/refund-new-key`, payload)
	},
	//Service to close deposit
	closeDeposit: (payload) => {
		return APIClient.post(`/deposits/close-deposit`, payload)
	},
	//report
	getDepositReport: () => {
		return APIClient.get(`/deposits/report`)
	},

	//create deposit
	createDeposit: (payload) => {
		return APIClient.post(`/deposits/create-deposit`, payload)
	},
	//update deposit
	updateDeposit: (payload) => {
		return APIClient.put(`/deposits/update-deposit`, payload)
	},
	//get deposit by id
	refundDeposit: (payload) => {
		return APIClient.post(`/deposits/refund-deposit`, payload)
	},
}

export default DepositServices
