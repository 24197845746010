<template>
	<div>
        <Loading v-if="loading" />
        <h2 v-if="warning">Page Not Found</h2>
		<h2 v-if="valid">Invalid or expired link</h2>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'

export default {
	name: 'Payment',
	components: {
		Loading,
	},
	data() {
		return {
			local: {
				loading: false,
			},
            warning:false,
			valid:false
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.authentication.currentUser,
            loading: (state) => state.profile.loading || state.subscription.isLoading,
			language: (state) => state.language
		}),
		...mapGetters('profile', {
			isLoaded: 'isLoaded',
		}),
	},
	async mounted() {
		if (!this.isLoaded) {
			await this.loadCurrentUser()
		}

		const urlParams = new URLSearchParams(window.location.search);
		
		  const subId = parseInt(urlParams.get('subId'));
		  const expirationTimestamp = Number(urlParams.get('expires'));
		  const coupon = parseInt(urlParams.get('coupon'));
		  const invoiceItem = Number(urlParams.get('invoiceItem'));
          const paymentMethod = urlParams.get('type')
		  if (isNaN(expirationTimestamp)) {
			// If expiration timestamp is not valid, consider link expired
			this.valid = true;
			return true;
          }
    
     
		  const currentDate = new Date().getTime();
		
		// Check if the current time is after the expiration timestamps
		if(currentDate > expirationTimestamp){
			this.valid = true;
			return true;
		}else{ 
			  // Check if 'coupon' parameter exists
			  if (subId && urlParams.has('coupon')) {
				const payload = {
					language : this.language.value,
					subId,
				}
				if (coupon) {
					payload.coupon = String(coupon);
				}
				const result = await this.initiateBancontact(payload)
				window.location.href = result
               }else if(subId && urlParams.has('invoiceItem')){
				const result = await this.sendPaymentLink({subId,invoiceItem});
		    	window.location.href = result
			  }else if(subId){
				const result = await this.renewBancontact({subId,type:paymentMethod?'creditcard':'bancontact'});
		    	window.location.href = result
			  }else{
				this.warning = true
			 }
		}
   
        
    
	},
	beforeDestroy() {
	},
	methods: {
		...mapActions({
            loadCurrentUser:'profile/getCurrentUser',
            renewBancontact:'profile/renewBancontact',
			sendPaymentLink:'profile/sendPaymentLink', 
			initiateBancontact: 'subscription/initiateBancontact',
		}),
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/login';
</style>
