<template>
	<div class="admin-item-row clickable sessions-row" @click="$emit('on-click', session.id)">
		<div class="admin-item-col medium">
			{{ parseInt(session.sessionId) }}
		</div>
		<div class="admin-item-col medium">
			<div v-if="session.sessionUser">{{ session.sessionUser.firstName }} {{ session.sessionUser.lastName }}</div>
		</div>
		<div class="admin-item-col small">
			{{ session.bikes || 0 }}
		</div>
		<div class="admin-item-col small">
			{{ session.sessionParking ? session.sessionParking.code : '-' }}
		</div>
		<div class="admin-item-col small">
			{{ time(session.startTimeInMs) }}
		</div>
		<div class="admin-item-col small">
			{{ getDuration(session) }}
			<!-- {{ diffInTime(session.startTime, session.endTime || new Date().getTime()) }} -->
		</div>
		<div class="admin-item-col small actions">
			<button v-if="session.active" class="button red" @click="cancelSession(session.id)">Cancel</button>
		</div>
	</div>
</template>

<script>
import { time, diffInTime } from '@/helpers'
import session from '../../../store/modules/session';
import { mapState } from 'vuex';
export default {
	name: 'SessionResult',
	components: {},
	props: {
		session: {
			type: Object,
			default: () => ({}),
		},
		cancelSession: {
			type: Function,
		},
	},
	data() {
		return {}
	},

	computed: {
		...mapState({
			serverTime: (state) => state.serverTime,
		}),
	},
	methods: {
		time,
		diffInTime,
		getDuration(sessionData) {
			const entryQr = sessionData.sessionQRCodes.find(qr => qr.qrType == 'ENT')
			if (entryQr && entryQr.used == entryQr.usage) {
				return this.diffInTime(sessionData.startTimeInMs, (sessionData.endTimeInMs || this.serverTime))
			}
			return this.diffInTime(sessionData.startTimeInMs, this.serverTime)
		}
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/pages/admin';
.admin-item-col {
	&.admin-user {
		color: red;
	}
}

.admin-item-row.toApprove {
	background-color: lighten($red, 20);
	&.sessions-row{
		.button{
			line-height: 34px;
		}
	}
}

.admin-item-row {
	&.sessions-row {
		.button {
			line-height: 34px;
		}
	}
}
</style>
