<template>
	<div class="profile-subscriptions">
		<Loading v-if="isLoading" />
		<div class="profile-title-section">
			<h2 style="color: #1b388a; font-size: 32px; line-height: 54px; font-family: 'Poppins Bold'">
				<!-- Current Active Plan -->
				{{ $t('profile.paymentMethods.plan.title') }}
			</h2>
		</div>
		<p v-if="subscriptions.length <= 0" class="profile-subscriptions-no-data">
			{{ $t('profile.subscription.noSubscription') }}
		</p>
		<div v-else-if="subscriptions.length > 0" class="profile-subscriptions-table">
			<div class="profile-subscriptions-table-header">
				<div class="profile-subscriptions-table-header-item size30">
					{{ $t('subscription.overviewHeaders.name') }}
				</div>
				<div class="profile-subscriptions-table-header-item size10">
					{{ $t('subscription.overviewHeaders.spotNumber') }}
				</div>
				<div class="profile-subscriptions-table-header-item size20">
					{{ $t('subscription.overviewHeaders.expiryDate') }}
				</div>
				<div class="profile-subscriptions-table-header-item size20">
					{{ $t('subscription.overviewHeaders.status') }}
				</div>
				<div class="profile-subscriptions-table-header-item actions" />
			</div>
			<div
				v-for="sub in subscriptions"
				v-if="isActive(sub) && !sub.cancelRequestedAt && !isExpired(sub)"
				:key="sub.id"
				class="profile-subscriptions-table-content"
			>
				<div class="profile-subscriptions-table-content-item size30">
					{{ subscriptionName(sub, $i18n.locale) }}
				</div>
				<div class="profile-subscriptions-table-content-item size10">
					{{ sub.spotNumber }}
				</div>
				<div class="profile-subscriptions-table-content-item size20">
					{{ date(sub.end) }}
				</div>
				<div class="profile-subscriptions-table-content-item size20">
					{{ $t(`subscription.statuses.${subscriptionStatusName(sub)}`) }}
				</div>
				<div
					class="profile-subscriptions-table-content-item actions button tiny red"
					@click="$emit('toggle-cancel-sub-modal', sub.id)"
				>
					{{ $t('profile.subscription.cancelButtonLabel') }}
				</div>

				<div class="profile-subscriptions-table-content-item actions" />
			</div>
		</div>
		<h2 style="color: #1b388a; font-size: 32px; line-height: 54px; font-family: 'Poppins Bold'">
			<!-- Invoice History -->
			{{ $t('profile.paymentMethods.plan.invoiceHistory') }}
		</h2>
		<div
			v-for="sub in subscriptions"
			v-if="isActive(sub) && !sub.cancelRequestedAt && !isExpired(sub)"
			:key="sub.id"
			class="invoice"
		>
			<h4>{{ sub.parkingCode }}</h4>
			<div v-for="invoice in sub.invoiceItems" class="entry">
				<span class="date">{{ date(invoice.invoice.createdAt) }}</span>
				<span class="amount">€{{ formattedAmount(invoice.invoice) }}</span>
				<span v-if="invoice.invoice.paid == true" class="status paid">Paid</span>
				<span v-else="invoice.invoice.paid == false" class="status open">Open</span>
				<span class="description">{{ invoice.description }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import { date } from '@/helpers'
import { subscriptionStatusName, isExpired, subscriptionName } from '@/store/modules/subscription'
import { config, SUBSCRIPTION_STATES } from '@/config'
import { euroCentConverter } from '../../store/modules/admin/exports'

const stripe = window.Stripe(config.stripePublicKey)

export default {
	name: 'Plans',
	components: {
		Loading,
	},
	props: {
		subscriptions: {
			type: Array,
			default: () => [],
		},
		proposals: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			// profileLocal: Object.assign({}, this.profile),
		}
	},
	computed: {
		...mapGetters('profile', {
			hasBigParkingSubscription: 'hasBigParkingSubscription',
		}),
		...mapGetters('subscription', {
			subIsRetryableForPaymentLink: 'subIsRetryableForPaymentLink',
		}),
		...mapState({
			subscription: (state) => state.subscription.current,
			isLoading: (state) => state.subscription.isLoading,
		}),
	},
	methods: {
		...mapActions('subscription', {
			refreshSubscription: 'refreshSubscription',
			undoCancelRequestSubscription: 'undoCancelRequestSubscription',
		}),
		...mapMutations('subscription', {
			setLoading: 'setLoading',
		}),
		date,
		subscriptionName,
		subscriptionStatusName,
		isExpired,

		async doRefreshSubscription(subId) {
			await this.refreshSubscription({ subId })

			if (this.subIsRetryableForPaymentLink) {
				this.$router.push({
					name: 'subscription',
					query: {
						subId: this.subscription.id,
						proposal: this.subscription.proposalId,
					},
				})

				return true
			} else {
				const paymentIntentSecret = this.subscription.scaPaymentIntentSecret

				if (paymentIntentSecret) {
					this.setLoading(true)

					return stripe.confirmCardPayment(paymentIntentSecret).then((result) => {
						if (result.error) {
							this.flash(result.error.message, 'error') // maybe no flash message needed
							window.location.reload()
						} else {
							setTimeout(() => {
								window.location.reload()
							}, 5000)
						}
					})
				} else {
					return true
				}
			}
		},
		isPending(sub) {
			return sub.status === SUBSCRIPTION_STATES.pending
		},

		isActive(sub) {
			return sub.status === SUBSCRIPTION_STATES.active
		},

		formattedAmount(invoice) {
			const amount = euroCentConverter(invoice.amount)
			if (invoice.mollieId !== null) {
				return (amount * 100).toFixed(2)
			} else if (invoice.mollieId === null && invoice.stripeId === null && amount < 1) {
				return (amount * 100).toFixed(2)
			} else {
				return amount.toFixed(2)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
.profile-subscriptions {
	font-family: 'Poppins Regular';
}
.invoice {
	max-width: 600px;
	margin: 20px auto;
	background-color: #fff;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.entry {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.date {
	flex-basis: 25%;
}

.amount {
	flex-basis: 20%;
}

.status {
	flex-basis: 15%;
	text-align: center;
	padding: 5px;
	border-radius: 5px;
	color: #fff;
	font-weight: bold;
}

.open {
	background-color: #ffcc00; /* yellow for open status */
}

.paid {
	background-color: #4caf50; /* green for paid status */
}

.description {
	flex-basis: 45%;
	margin-left: 30px;
}
</style>
