// Export * from './auth-header'
import i18n from '@/i18nVeeValidate'
import { findKey } from 'lodash/fp'
import { config } from '../config'

export const date = (intDate, opts = {}) => {
	let dString = '-'
	const dateSeparator = opts.dateSeparator || '-'

	if (intDate) {
		const d = new Date(intDate)

		dString = `${d.getDate()}${dateSeparator}${d.getMonth() + 1}${dateSeparator}${d.getFullYear()}`

		if (opts.withHours) {
			let minutes = `${d.getMinutes()}`

			if (minutes.length === 1) {
				minutes = `0${minutes}`
			}

			dString += ` ${d.getHours()}:${minutes}`
		}

		if (opts.withSeconds) {
			let seconds = `${d.getSeconds()}`

			if (seconds.length === 1) {
				seconds = `0${seconds}`
			}

			dString += `'${seconds}`
		}
	}

	return dString
}

export const time = (intDate, opts = {}) => {
	let dString = ''

	if (intDate) {
		const d = new Date(intDate)

			let minutes = `${d.getMinutes()}`

			if (minutes.length === 1) {
				minutes = `0${minutes}`
			}

			dString += `${d.getHours()}:${minutes}`

			let seconds = `${d.getSeconds()}`

			if (seconds.length === 1) {
				seconds = `0${seconds}`
			}

			dString += `:${seconds}`
	}

	return dString
}
export const diffInTime = (startDate, endDate) => {
	let dString = ''

	if (startDate && endDate) {
		const diffMs = Math.abs(startDate - endDate); // this is a time in milliseconds
		
		dString += Math.floor(diffMs / 3600000).toString().padStart(2, '0'); // hours
		dString += `:${Math.round((diffMs % 3600000) / 60000).toString().padStart(2, '0')}`; // minutes
		dString += `:${Math.round(((diffMs % 3600000) % 60000) / 1000).toString().padStart(2, '0')}`; // seconds
	}

	return dString
}

export const parsePhone = (phone) => {
	return phone.replace(/ /g, '').replace('+', '')
}

export const toggleRevealOld = (scope, field) => {
	if (!scope.local[field].reveal) {
		scope.$refs[field].setAttribute('type', 'text')
	} else {
		scope.$refs[field].setAttribute('type', 'password')
	}

	return !scope.local[field].reveal
}

export const toggleReveal = (field, ref) => {
	if (!field.reveal) {
		ref.setAttribute('type', 'text')
	} else {
		ref.setAttribute('type', 'password')
	}

	return !field.reveal
}

export const tryTranslate = (value, prefix) => {
	return i18n.te(`${prefix}.${value.toLowerCase()}`)
		? i18n.t(`${prefix}.${value.toLowerCase()}`)
		: value
}

export const getStatusName = (statuses, status) => {
	return findKey((s) => s === status, statuses)
}

export const tryString = (value, opts = {}) => {
	if (value) {
		return `${opts.prefix || ''}${value}`
	} else {
		return opts.default || ''
	}
}

export const extractGraphqlError = (error) => {
	// const regex = /{(.*?)}/
	// const match = error.match(regex)

	return error.replace('GraphQL error: ', '').trim()
}

export const formatAddressObj = (address) => {
	// street name house number, postal code  city name, municiplaity, Belgium
	const partOne = [
		address?.road,
		address?.house_number || address?.building,
	]
	.filter((value) => ![null, undefined].includes(value))

	const partOneString = partOne.length ? partOne.join(' ') : null

	const partTwo = [
		address?.postcode,
		address?.municipality ?? address?.city ?? address?.town ?? address?.village,
		address?.city,
	].filter((value) => ![null, undefined].includes(value))

	const partTwoString = partTwo.length ? partTwo.join(' ') : null

	// order senstative
	const formattedAddress = [
		partOneString,
		partTwoString,
		address?.country
	]
	// remove 'undefined' and 'null'
	.filter((value) => ![null, undefined].includes(value))
	.join(', ')

	return formattedAddress
}

export const uploadFiles = async (files) => {
	let data = new FormData();
	for (let file of files) {
		data.append('files', file);
	}
	const token = localStorage.getItem('cycloToken')
	const response = await fetch(`${config.apiUrl}/upload`, {
		method: "POST",
		body: data,
		headers: {
			Authorization: token ? `Bearer ${token}` : null
		}
	})
	let fileResponse = await response.json();
	return fileResponse.data || [];
}
