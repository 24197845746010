import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { logger } from '@/logger'
import { findIndex } from 'lodash/fp'
import Vue from 'vue'
import PostcodePricesService from '../../../services/postCodePrices'

// import { keyTypes } from '@/config'
// import { sortBy, findIndex, find, filter, values, groupBy, flatten, map, min, compose } from 'lodash/fp'

// Initial state
const initialState = () => ({
	all: [],
	loading: false,
})

export const pcPricesProps = `
	id
	name
	parkingTypeName
	postalCode
	price
	isCargo
	active
`

const state = initialState()

export const initialPcPriceState = {
	name: '',
	postalCode: '',
	price: '',
	parkingTypeName: {},
	isCargo: false,
	active: true,
}

const getters = {}

const actions = {
	async getAllPostcodePrices({ commit }) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.query({
			// 	query: gql`
			// 		query allPostcodePrices {
			// 			allPostcodePrices {
			// 				${pcPricesProps}
			// 			}
			// 		}
			// 	`,
			// })
			const params = {
				offset : 0,
				limit : 0, // 0 means no limit
				sortkey : 'id',
				sortorder:'ASC'
			}
			const response = await PostcodePricesService.getPostcodePrices(params)
			const pcPrices = response.data.allPostcodePrices

			commit('setPcPrices', pcPrices)
		} catch (e) {
			logger.error('Problem retrieving postcode prices', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async upsertPostcodePrice({ commit, dispatch }, payload) {
		// console.log('creating parking', parking)

		commit('setLoading', true)

		const pcPrice = Object.assign({}, payload, {
			parkingTypeName: payload.parkingTypeName.key,
			price: parseFloat(payload.price),
		})

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation upsertPostcodePrice($pcPrice: PostcodePriceUpsertProps!) {
			// 			upsertPostcodePrice(props: $pcPrice) {
			// 				${pcPricesProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		pcPrice,
			// 	},
			// })
			// 
			const payload = {
				active: pcPrice.active,
				isCargo: pcPrice.isCargo,
				name: pcPrice.name,
				parkingTypeName: pcPrice.parkingTypeName,
				postalCode: pcPrice.postalCode,
				price: pcPrice.price,
				id: pcPrice.id
			}
			const response = await PostcodePricesService.upsertPostcodePrice(payload)

			if (response.data) {
				pcPrice.id
					? commit('updatePcPrice', response.data)
					: commit('createPcPrice', { id: response.data.id, pcPrice })

				return true
			}
		} catch (e) {
			logger.error('Postcode price creation error')

			// dispatch('alert/error', e.message, { root: true })
			dispatch('alert/error', 'Failed to store price rule, please check for duplicates.', {
				root: true,
			})
			throw e
		} finally {
			commit('setLoading', false)
		}

		return false
	},
}

const mutations = {
	setLoading(state, loadingState) {
		state.loading = loadingState
	},
	setPcPrices(state, pcPrices) {
		state.all = pcPrices
	},
	setSelectedParking(state, parking) {
		state.selectedParking = parking
	},
	createPcPrice(state, { id, pcPrice }) {
		state.all.push(Object.assign({}, pcPrice, { id }))
	},
	updatePcPrice(state, pcPrice) {
		const idx = findIndex({ id: pcPrice.id }, state.all)

		if (idx >= 0) {
			Vue.set(state.all, idx, pcPrice)
		}
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
