<template>
	<div class="profile-create-account">
		<h1 class="profile-create-account-title title">
			{{ $t('profile.createFamilyAccount') }}
		</h1>
		<form
			class="profile-create-account-form"
			data-vv-scope="create-account-form"
			autocomplete="off"
			@submit.prevent="validateFormBeforeSubmit"
		>
			<h3 class="profile-create-account-field-title">*{{ $t('profile.firstNameLabel') }}</h3>
			<div class="input-box profile-create-account-form-field">
				<input
					v-model="account.firstName"
					v-validate="{ required: true }"
					:placeholder="$t('profile.firstNameLabel')"
					:class="{ error: errors.has('create-account-form.firstName') }"
					:data-vv-as="$t('profile.firstNameLabel')"
					name="firstName"
					autocomplete="off"
					type="text"
					class="input-field"
				/>
				<p v-show="errors.has('create-account-form.firstName')" class="input-error">
					{{ errors.first('create-account-form.firstName') }}
				</p>
			</div>
			<h3 class="profile-create-account-field-title">*{{ $t('profile.lastNameLabel') }}</h3>
			<div class="input-box profile-create-account-form-field">
				<input
					v-model="account.lastName"
					v-validate="{ required: true }"
					:placeholder="$t('profile.lastNameLabel')"
					:class="{ error: errors.has('create-account-form.lastName') }"
					:data-vv-as="$t('profile.lastNameLabel')"
					name="lastName"
					autocomplete="off"
					type="text"
					class="input-field"
				/>
				<p v-show="errors.has('create-account-form.lastName')" class="input-error">
					{{ errors.first('create-account-form.lastName') }}
				</p>
			</div>
			<h3 class="profile-create-account-field-title" :style="{display:'flex'}" @mousedown="toggleTooltip()" 
							@keyup.enter="toggleTooltip()"
							@keydown.space.prevent="toggleTooltip()">
							{{ $t('profile.childEmailLabel') }} 
							<div :style="{position:'relative',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}">
							<img src="../../assets/images/info-circle.svg" 
							class="icon-info" 
							alt="Info icon"
							tabindex="0"
							aria-label="Toggle tooltip"
							:style="{paddingLeft:'3px'}"
							/>
							<div v-if="showchildEmailTooltip" class="tooltip-box" role="tooltip">
								{{ $t('profile.childEmailTooltip') }}
							</div>
						</div></h3>
			<div class="input-box profile-create-account-form-field">
				<input
					v-model="account.childEmail"
					v-validate="'email'"
					:placeholder="$t('profile.childEmailLabel')"
					:class="{ error: errors.has('create-account-form.childEmail') }"
					:data-vv-as="$t('profile.childEmailLabel')"
					name="childEmail"
					autocomplete="off"
					type="text"
					class="input-field"
				/>
				<p v-show="errors.has('create-account-form.childEmail')" class="input-error">
					{{ errors.first('create-account-form.childEmail') }}
				</p>
			</div>
			<h3 class="profile-create-account-field-title">
				{{ $t('profile.mobibLabel') }}
			</h3>
			<div class="input-box profile-create-account-form-field">
				<MobibInput
					:mobib.sync="account.mobib"
					:mobibPresent="false"
					:message="$t('profile.mobibInfo')"
					errorTag="create-account-form.mobib"
					autocomplete="off"
					class="input-box profile-create-account-form-mobib"
				/>
				<p v-show="errors.has('create-account-form.mobib')" class="input-error">
					{{ errors.first('create-account-form.mobib') }}
				</p>
			</div>
			<div class="profile-create-account-form-actions">
				<!-- <button class="button red" @mousedown="$emit('toggleForm', { open: false })">
					{{ $t('general.cancelLabel') }}
				</button> -->
				<button
					:disabled="errors.any('create-account-form')"
					:class="{ disabled: errors.any('create-account-form') }"
					class="button"
					type="submit"
				>
					{{ $t('signup.createButtonLabel') }}
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MobibInput from '@/components/form/MobibInput.vue'

export default {
	name: 'CreateAccount',
	components: {
		MobibInput,
	},
	props: {},
	data() {
		return {
			account: {
				firstName: '',
				lastName: '',
				childEmail: '',
				mobib: '',
			},
			showchildEmailTooltip:false,
		}
	},
	computed: {
		...mapState({}),
	},
	mounted() {
		this.$validator.pause()
	},
	methods: {
		...mapActions({
			addAccount: 'profile/addChildAccount',
		}),
		validateFormBeforeSubmit() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			this.$validator.validateAll('create-account-form').then((result) => {
				if (result) {
					this.$validator.pause()

					// if (await this.addAccount(this.account)) {
					this.addAccount(this.account)
					this.$emit('toggleForm', { open: false })
				}
			})
		},
		toggleTooltip() {
      this.showchildEmailTooltip = !this.showchildEmailTooltip;
    },
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/profile/createAccount';
.icon-info{
	width: 12px
}
.tooltip-box{
		position: absolute;
    background: black;
    color: white;
    padding: 8px;
    min-width: 500px;
    top: inherit;
    border-radius: 8px;
		left: 16px;

	}
	@media screen and (max-width: 600px) {
		.tooltip-box {
		min-width: 250px;
  }
	}
</style>
