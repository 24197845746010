import APIClient from '../api/apiClient'

const SessionService = {
	/**
	 * @description will get all session of all users, will be used by admin only
	 */
	getAllSessions: (params, body) => {
		// add body with params
		return APIClient.post(`/user-sessions/all-sessions`, body, { params })
		// return APIClient.post(`/user-sessions/all-sessions`, { params })
	},

	/**
	 * @description will create new session for a user with given sessionData
	 */
	createNewSession: (sessionData) => {
		return APIClient.post(`/user-sessions/session-operations`, sessionData)
	},

	/**
	 * @description will start session specified by sessionId
	 */
	startSession: (sessionId) => {
		return APIClient.put(`/user-sessions/session-operations/${sessionId}/start`, {})
	},

	/**
	 * @description will update user session by given data
	 */
	updateSession: (sessionId, sessionData) => {
		return APIClient.put(`/user-sessions/session-operations/${sessionId}/update`, sessionData)
	},

	/**
	 * @description will get user session detail by sessionId
	 */
	getSessionById: async (sessionId) => {
		const response = await APIClient.get(`/user-sessions/session-operations/${sessionId}`)
		const qrScanLogs = []
	
		response.data.sessionQRCodes.forEach((sessionQRcode) => {
			if (sessionQRcode.qrScanLogs) {
				qrScanLogs.push(...sessionQRcode.qrScanLogs)
			}
		})
	
		response.data.qrScanLogs = qrScanLogs
	
		return response
	},

	/**
	 * @description  will generate qrcode of specific type for an active session
	 */
	generateSessionQRCode: (sessionId, payload) => {
		return APIClient.post(`/user-sessions/session-operations/${sessionId}/generate-qr`, payload)
	},

	/**
	 * @description will get all sessions of a user by user id
	 */
	getSessionsByUserId: (userid) => {
		return APIClient.get(`/user/user-operations/${userid}/user-sessions`).then((response) => {
			return response.data || []
		})
	},

	/**
	 * @description will get user live session if any
	 */
	getLiveSessionByUserId: (userid) => {
		return APIClient.get(`/user/user-operations/${userid}/user-live-session`).then((response) => {
			return response?.data || null
		})
	},

	/**
	 * @description will get all mandets if any
	 */
	getAllMandates: () => {
		return APIClient.get(`/user/get-all-mandates`).then((response) => {
			console.log('response', response)
			return response?.data || null
		})
	},

	/**
	 * @description will cancel session for user
	 */
	cancelCurrentSession: (sessionId) => {
		return APIClient.put(`/user-sessions/session-operations/${sessionId}/cancel`).then(
			(response) => {
				return response?.data || null
			}
		)
	},
	//service to end the session
	endSession: (sessionId) => {
		return APIClient.post(`/user-sessions/session-operations/${sessionId}/end`).then((response) => {
			return response?.data || null
		})
	},
	//Service to update the qr usage
	updateQRUsage: (payload) => {
		return APIClient.post(`/user-sessions/update-qr-usage`, payload)
	},
}

export default SessionService
