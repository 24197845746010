<template>
	<div>
		<Loading v-if="loading" />
		<Menu activeMenu="parking" />
		<div class="admin-parking">
			<div class="admin-parking-info-header">
				<h2 class="admin-parking-info-header-title">
					{{
						formOpen
							? parking.id
								? $t('admin.parking.updateParkingLabel')
								: $t('admin.parking.createParkingLabel')
							: $t('admin.parking.searchParkingLabel')
					}}
				</h2>
				<div>
					<button
						v-if="formOpen"
						class="button admin-parking-info-header-button red"
						@click="doDeleteParking"
					>
						DELETE
					</button>
					<button
						:class="{ blue: !formOpen, red: formOpen }"
						class="button admin-parking-info-header-button"
						@click="toggleForm"
					>
						{{ formOpen ? $t('general.cancelLabel') : $t('admin.parking.createParkingLabel') }}
					</button>
				</div>
			</div>
			<div v-if="userParking">Parking owner: {{ parking.owner.email }}</div>
			<form
				v-if="formOpen"
				class="admin-parking-form"
				data-vv-scope="parking-form"
				autocomplete="off"
				@submit.prevent="validateFormBeforeSubmit"
			>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.name"
						v-validate="{ required: true }"
						:placeholder="$t('admin.parking.name')"
						:class="{ error: errors.has('parking-form.parkingName') }"
						:data-vv-as="$t('admin.parking.name')"
						name="parkingName"
						type="text"
						class="input-field"
					/>
					<p v-show="errors.has('parking-form.parkingName')" class="input-error">
						{{ errors.first('parking-form.parkingName') }}
					</p>
				</div>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.code"
						v-validate="{ required: true }"
						:placeholder="$t('admin.parking.parkingCodeLabel')"
						:class="{ error: errors.has('parking-form.parkingCode') }"
						:data-vv-as="$t('admin.parking.parkingCodeLabel')"
						name="parkingCode"
						type="text"
						class="input-field"
					/>
					<p v-show="errors.has('parking-form.parkingCode')" class="input-error">
						{{ errors.first('parking-form.parkingCode') }}
					</p>
				</div>
				<div v-if="!userParking" class="input-box admin-parking-form-field">
					<input
						v-model="parking.gateNames"
						:placeholder="$t('admin.parking.parkingGateNamesLabel')"
						:class="{ error: errors.has('parking-form.parkingGateNames') }"
						:data-vv-as="$t('admin.parking.parkingGateNamesLabel')"
						name="parkingGateNames"
						type="text"
						class="input-field"
					/>
					<p v-show="errors.has('parking-form.parkingGateNames')" class="input-error">
						{{ errors.first('parking-form.parkingGateNames') }}
					</p>
				</div>
				<div v-if="!userParking" class="input-box admin-parking-form-field">
					<input
						v-model="parking.insideGateNames"
						:placeholder="$t('admin.parking.insideGateNamesLabel')"
						:class="{ error: errors.has('parking-form.insideGateNames') }"
						:data-vv-as="$t('admin.parking.insideGateNamesLabel')"
						name="insideGateNames"
						type="text"
						class="input-field"
					/>
					<p v-show="errors.has('parking-form.insideGateNames')" class="input-error">
						{{ errors.first('parking-form.insideGateNames') }}
					</p>
				</div>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.legacyAddressNl"
						v-validate="{ required: true }"
						:placeholder="`${$t('admin.parking.addressLabel')} (NL)`"
						:class="{ error: errors.has('parking-form.legacyAddressNl') }"
						:data-vv-as="$t('admin.parking.addressLabel')"
						name="legacyAddressNl"
						type="text"
						class="input-field profile-input-field"
					/>
					<p v-show="errors.has('parking-form.legacyAddressNl')" class="input-error">
						{{ errors.first('parking-form.legacyAddressNl') }}
					</p>
				</div>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.legacyAddressFr"
						v-validate="{ required: true }"
						:placeholder="`${$t('admin.parking.addressLabel')} (FR)`"
						:class="{ error: errors.has('parking-form.legacyAddressFr') }"
						:data-vv-as="$t('admin.parking.addressLabel')"
						name="legacyAddressFr"
						type="text"
						class="input-field profile-input-field"
					/>
					<p v-show="errors.has('parking-form.legacyAddressFr')" class="input-error">
						{{ errors.first('parking-form.legacyAddressFr') }}
					</p>
				</div>
				<div class="input-box admin-parking-form-field multiple">
					<div class="admin-parking-form-field-box">
						<input
							v-model="parking.latitude"
							v-validate="{ required: true, regex: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/ }"
							:class="{ error: errors.has('parking-form.latitude') }"
							:placeholder="$t('admin.parking.latitude')"
							data-vv-as="Latitude"
							name="latitude"
							type="text"
							class="input-field inline profile-input-field"
						/>
						<p v-show="errors.has('parking-form.latitude')" class="input-error">
							{{ errors.first('parking-form.latitude') }}
						</p>
					</div>
					<div class="admin-parking-form-field-box">
						<input
							v-model="parking.longitude"
							v-validate="{
								required: true,
								regex: /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
							}"
							:class="{ error: errors.has('parking-form.longitude') }"
							:placeholder="$t('admin.parking.longitude')"
							data-vv-as="Longitude"
							name="longitude"
							type="text"
							class="input-field inline profile-input-field"
						/>
						<p v-show="errors.has('parking-form.longitude')" class="input-error">
							{{ errors.first('parking-form.longitude') }}
						</p>
					</div>
				</div>
				<div class="input-box admin-parking-form-field">
					<date-picker
						v-model="parking.installationDate"
						:clearable="false"
						lang="en"
						format="DD-MM-YYYY"
						placeholder="Select installation date"
						confirm
						confirmText="Select"
						inputClass="input-field"
					/>
				</div>
				<label class="profile-input-field-label">{{ $t('admin.parking.commentInfo') }}</label>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.commentNl"
						:placeholder="`${$t('admin.parking.comment')} (NL)`"
						name="commentNl"
						type="text"
						class="input-field profile-input-field"
					/>
				</div>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.commentFr"
						:placeholder="`${$t('admin.parking.comment')} (FR)`"
						name="commentFr"
						type="text"
						class="input-field profile-input-field"
					/>
				</div>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.commentEn"
						:placeholder="`${$t('admin.parking.comment')} (EN)`"
						name="commentEn"
						type="text"
						class="input-field profile-input-field"
					/>
				</div>
				<Dropdown
					v-if="!userParking"
					:value="parking.keyType.value"
					:options="keyTypes"
					:selected="parking.keyType"
					:placeholder="$t('admin.parking.keyType')"
					:class="{ error: hasCustomError && customErrors.hasOwnProperty('keyType') }"
					name="keyType"
					class="admin-parking-form-field"
					@on-click="(option) => selectKeyType(option.key)"
				/>
				<p v-show="hasCustomError && customErrors.hasOwnProperty('keyType')" class="input-error">
					{{ customErrors.keyType }}
				</p>
				<Dropdown
					v-if="!userParking"
					:value="parking.parkingTypeName.value"
					:options="parkingTypes"
					:selected="parking.parkingTypeName"
					:placeholder="$t('admin.parking.parkingType')"
					:class="{ error: hasCustomError && customErrors.hasOwnProperty('parkingTypeName') }"
					name="parkingTypeName"
					class="admin-parking-form-field"
					@on-click="(option) => selectParkingType(option.key)"
				/>
				<p
					v-show="hasCustomError && customErrors.hasOwnProperty('parkingTypeName')"
					class="input-error"
				>
					{{ customErrors.parkingTypeName }}
				</p>
				<div class="input-box admin-parking-form-field multiple">
					<div class="admin-parking-form-field-box">
						<input
							v-model="parking.capacityClassic"
							v-validate="{ required: true, numeric: true }"
							:placeholder="$t('admin.parking.classic')"
							:class="{ error: errors.has('parking-form.classic') }"
							:data-vv-as="$t('admin.parking.classic')"
							name="classic"
							type="text"
							class="input-field inline profile-input-field"
							@keyup="capacityChanged('Standard', parking.capacityClassic)"
						/>
						<p v-show="errors.has('parking-form.classic')" class="input-error">
							{{ errors.first('parking-form.classic') }}
						</p>
					</div>
					<div class="admin-parking-form-field-box">
						<input
							v-model="parking.capacityCargo"
							v-validate="{ required: true, numeric: true }"
							:placeholder="$t('admin.parking.cargo')"
							:class="{ error: errors.has('parking-form.cargo') }"
							:data-vv-as="$t('admin.parking.cargo')"
							name="cargo"
							type="text"
							class="input-field inline profile-input-field"
							@keyup="capacityChanged('Cargo', parking.capacityCargo)"
						/>
						<p v-show="errors.has('parking-form.cargo')" class="input-error">
							{{ errors.first('parking-form.cargo') }}
						</p>
					</div>
					<div class="admin-parking-form-field-box">
						<input
							v-model="parking.limitSubNumber"
							v-validate="{ required: false, regex: /\d/ }"
							:placeholder="$t('admin.parking.limitSubNumber')"
							:class="{ error: errors.has('parking-form.limitSubNumber') }"
							:data-vv-as="$t('admin.parking.limitSubNumber')"
							name="limitSubNumber"
							type="text"
							class="input-field inline profile-input-field"
						/>
						<p v-show="errors.has('parking-form.limitSubNumber')" class="input-error">
							{{ errors.first('parking-form.limitSubNumber') }}
						</p>
					</div>
				</div>
				<div class="input-box admin-parking-form-field">
					<input
						v-model="parking.adminInfo"
						:placeholder="$t('admin.parking.adminInfo')"
						name="adminInfo"
						type="text"
						class="input-field profile-input-field"
					/>
				</div>
				<div v-if="!isBigParking">
					<h2>Parking spot prices:</h2>
					<ParkingSpot
						v-for="(parkingSpot, index) in parking.parkingSpots"
						:key="index"
						:parkingSpot.sync="parking.parkingSpots[index]"
						:sIndex="index"
						:parkingSpotErrors.sync="parkingSpotErrors"
						@removeSpot="removeParkingSpot($event)"
					/>
					<div class="input-box admin-parking-form-field">
						<div class="button blue notLong" @click="addParkingSpot()">Add Price</div>
					</div>
				</div>
				<!-- session fields -->
				<div class="session-field-container">
					<Checkbox v-model="parking.hasSessionParking" class="admin-parking-form-checkbox" name="hasSessionParking">
						<span slot="label" class="checkbox-text text">
							{{ userParking ? $t('Provides Session Parking?') : $t('Provides Session Parking?') }}
						</span>
					</Checkbox>
					<div v-if="parking.hasSessionParking">
						<div class="input-box admin-parking-form-field multiple">
							<div class="admin-parking-form-field-box">
								<input
									v-model="parking.sessionConfig.subscriptionFreeHours"
									v-validate="{ required: true, numeric: true }"
									:placeholder="$t('No of free hours')"
									:class="{ error: errors.has('parking-form.sessionConfig.subscriptionFreeHours') }"
									:data-vv-as="$t('admin.parking.sessionConfig.subscriptionFreeHours')"
									name="sessionConfig.subscriptionFreeHours"
									type="text"
									class="input-field inline profile-input-field"
								/>
								<p v-show="errors.has('parking-form.sessionConfig.subscriptionFreeHours')" class="input-error">
									{{ errors.first('parking-form.sessionConfig.subscriptionFreeHours') }}
								</p>
							</div>
							<div class="admin-parking-form-field-box">
								<input
									v-model="parking.sessionConfig.perHourCost"
									v-validate="{ required: true, decimal: true }"
									:placeholder="$t('Price per hour')"
									:class="{ error: errors.has('parking-form.sessionConfig.perHourCost') }"
									:data-vv-as="$t('admin.parking.sessionConfig.perHourCost')"
									name="sessionConfig.perHourCost"
									type="text"
									class="input-field inline profile-input-field"
								/>
								<p v-show="errors.has('parking-form.sessionConfig.perHourCost')" class="input-error">
									{{ errors.first('parking-form.sessionConfig.perHourCost') }}
								</p>
							</div>
						</div>
					</div>
				</div>

				<!-- parking Images -->
				<h3>Parking Images</h3>
				<div class="image-container">
					<div class="image" v-for="(parkingImage, index) in parking.images" :key="index">
						<img :src="parkingImage" alt="parking view"/>
						<img src="../../assets/images/rubbish-bin.svg" alt="remove" class="remove" @click="()=>removeParkingImage(index)"/>
					</div>
					<div class="image" v-for="(parkingImageFile, index) in parking.imagesToUpload" :key="`to-upload-${index}`">
						<img :src="createObjectURL(parkingImageFile)" alt="parking view"/>
						<img src="../../assets/images/rubbish-bin.svg" alt="remove" class="remove" @click="()=>removeParkingImagesToUpload(index)"/>
					</div>
					<div class="upload">
						<div>
							<label for="upload" class="button blue">Choose file</label>
							<div v-if="parking.imagesToUpload && parking.imagesToUpload.length > 0">selected {{ parking.imagesToUpload.length }} files</div>
						</div>
						<input type="file" id="upload" hidden multiple accept="image/*"
							@change="handleChangeParkingImagesUpload"
							name="files"/>
					</div>
				</div>
				<Checkbox v-model="parking.active" class="admin-parking-form-checkbox" name="active">
					<span slot="label" class="checkbox-text text">
						{{ userParking ? $t('admin.parking.isActivePP') : $t('admin.parking.isActive') }}
					</span>
				</Checkbox>
				<Checkbox
					v-if="!userParking && isBigParking"
					v-model="parking.isAllAccess"
					class="admin-parking-form-checkbox"
					name="allAccess"
				>
					<span slot="label" class="checkbox-text text">
						{{ $t('admin.parking.isAllAccess') }}
					</span>
				</Checkbox>
				<Checkbox
					v-if="!userParking"
					v-model="parking.isCycloparking"
					class="admin-parking-form-checkbox"
					name="isCycloparking"
				>
					<span slot="label" class="checkbox-text text">
						{{ $t('admin.parking.isCycloparking') }}
					</span>
				</Checkbox>
				<Checkbox
					v-if="!userParking"
					v-model="parking.badgeAccess"
					class="admin-parking-form-checkbox"
					name="badgeAccess"
				>
					<span slot="label" class="checkbox-text text">
						{{ $t('admin.parking.badgeAccess') }}
					</span>
				</Checkbox>
				<Checkbox
					v-if="userParking"
					v-model="parking.approved"
					class="admin-parking-form-checkbox"
					name="approved"
				>
					<span slot="label" class="checkbox-text text">
						{{ $t('admin.parking.approved') }}
					</span>
				</Checkbox>
				<div class="admin-parking-form-actions">
					<button
						:disabled="errors.any('parking-form') || hasCustomError"
						:class="{ disabled: errors.any('parking-form') || hasCustomError }"
						class="button blue"
						type="submit"
					>
						{{ parking.id ? 'Update' : 'Create' }}
					</button>
				</div>
			</form>
			<div v-else class="admin-parking-search">
				<form
					class="admin-parking-form"
					data-vv-scope="parking-search-form"
					autocomplete="off"
					@submit.prevent="searchForm()"
				>
					<div class="admin-parking-search-content">
						<div class="admin-parking-search-field">
							<input
								ref="search"
								v-model="query"
								v-validate="{ required: true, min: 2 }"
								:placeholder="$t('admin.parking.searchParkingLabel')"
								:class="{ error: errors.has('parking-search-form.search') }"
								name="search"
								type="text"
								class="input-field admin-input-field input admin-search-field"
							/>
							<p v-show="errors.has('parking-search-form.search')" class="input-error">
								{{ errors.first('parking-search-form.search') }}
							</p>
						</div>
						<button
							:disabled="errors.any('parking-search-form')"
							:class="{ disabled: errors.any('parking-search-form') }"
							class="button blue admin-parking-search-button"
							type="submit"
						>
							Search
						</button>
					</div>
				</form>
				<section v-if="visibleParkings.length > 0">
					<div class="admin-item-row">
						<div class="admin-item-col smaller header">CODE</div>
						<div class="admin-item-col small header">Name</div>
						<div class="admin-item-col small header">install date</div>
						<div class="admin-item-col small header">type</div>
						<div class="admin-item-col smaller header">active</div>
						<div class="admin-item-col smaller header">cyclo</div>
						<div class="admin-item-col smaller header">deposit</div>
					</div>
					<ParkingResult
						v-for="(p, index) in visibleParkings"
						:key="index"
						:parking="p"
						@on-click="editParking($event)"
					/>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import Menu from '@/components/admin/Menu'
import Dropdown from '@/components/form/Dropdown.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import ParkingResult from '@/components/admin/parking/ParkingResult.vue'
import ParkingSpot from '@/components/admin/parking/ParkingSpot.vue'
import ValidationRequired from '@/mixins/validation-required'
import DatePicker from 'vue2-datepicker'
import { filter, find, omit, includes } from 'lodash/fp'
import { bigParkingTypes } from '@/config'
import Vue from 'vue'

/* import { logger } from '@/logger' */

export default {
	name: 'AdminParking',
	components: {
		Loading,
		Menu,
		Dropdown,
		Checkbox,
		DatePicker,
		ParkingResult,
		ParkingSpot,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			initialParkingState: {
				name: '',
				parkingTypeName: {},
				code: '',
				keyType: {},
				legacyAddressNl: '',
				legacyAddressFr: '',
				active: false,
				parkingSpots: [],
				isCycloparking: true,
				badgeAccess: false,
				longitude: '',
				latitude: '',
				installationDate: null,
				hasSessionParking: false,
				sessionConfig:{
					subscriptionFreeHours: null,
					perHourCost: null,
				},
				images: [],
				imagesToUpload: null,
			},
			parking: {},
			query: '',
			formOpen: false,
			visibleParkings: [],
			customErrors: {},
			parkingSpotErrors: {},
			hasCustomError: false,
		}
	},
	computed: {
		...mapState('admin', {
			allParkings: (state) => state.parkings.all,
			loading: (state) => state.loading,
		}),
		...mapGetters('admin', {
			parkingTypes: 'getParkingTypes',
			keyTypes: 'getKeyTypes',
		}),
		userParking() {
			return Boolean(this.parking.ownerId)
		},
		isBigParking() {
			return Boolean(includes(this.parking.parkingTypeName.key, bigParkingTypes))
		},
	},
	mounted() {
		this.$validator.pause()
		this.getParkings()
		this.parking = this.initialParkingState
	},
	methods: {
		...mapActions('admin', {
			getParkings: 'getParkings',
			upsertParking: 'upsertParking',
			deleteParking: 'deleteParking',
		}),
		createObjectURL: URL.createObjectURL,

		selectParkingType(optionKey) {
			if (this.customErrors.parkingTypeName) {
				delete this.customErrors.parkingTypeName

				if (Object.keys(this.customErrors).length < 1) {
					this.hasCustomError = false
				}
			}

			this.parking.parkingTypeName = this.parkingTypes.find((type) => type.key === optionKey)
		},

		selectKeyType(optionKey) {
			if (this.customErrors.keyType) {
				delete this.customErrors.keyType

				if (Object.keys(this.customErrors).length < 1) {
					this.hasCustomError = false
				}
			}

			this.parking.keyType = this.keyTypes.find((type) => type.key === optionKey)
		},
		validateFormBeforeSubmit() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			if (!this.loading) {
				this.$validator.validateAll('parking-form').then(async (result) => {
					// this.addressTypeError = await this.parking.parkingTypeName.key && await this.parking.keyType.key

					let ok = true

					// if (!this.parking.parkingTypeName.hasOwnProperty('key')) {
					if (!Object.prototype.hasOwnProperty.call(this.parking.parkingTypeName, 'key')) {
						this.customErrors.parkingTypeName = 'Required'
						this.hasCustomError = true
						ok = false
					}

					// if (!this.parking.keyType.hasOwnProperty('key')) {
					if (!Object.prototype.hasOwnProperty.call(this.parking.keyType, 'key')) {
						this.customErrors.keyType = 'Required'
						this.hasCustomError = true
						ok = false
					}

					if (!this.isBigParking && !this.validateParkingSpots()) {
						ok = false
					}

					if (!this.isBigParking && this.parking.isAllAccess) {
						this.parking.isAllAccess = false
					}

					// if (result && !this.addressTypeError) {
					if (result && ok) {
						this.$validator.pause()

						if (await this.upsertParking(omit(['owner', 'ownerId'], this.parking))) {
							this.formOpen = false
							this.searchForm()
						}
					}
				})
			}
		},
		findParkings(query) {
			const re = new RegExp(query, 'i')

			return filter(
				(parking) => parking.code && (parking.code.search(re) >= 0 || parking.name.search(re) >= 0),
				this.allParkings
			)
		},
		searchForm() {
			if (this.$validator._paused) {
				this.$validator.resume()
			}

			this.$validator.validateAll('parking-search-form').then((result) => {
				if (result) {
					this.$validator.pause()
					this.visibleParkings = this.findParkings(this.query)
				}
			})
		},
		toggleForm() {
			if (!this.formOpen) {
				this.parking = this.initialParkingState
			}

			this.formOpen = !this.formOpen
		},

		async doDeleteParking() {
			if (confirm('Are you sure you want to delet this parking?')) {
				if (await this.deleteParking(this.parking.id)) {
					this.formOpen = false
					this.visibleParkings = []
					this.query = ''
				}
			}
		},

		editParking(id) {
			const editParking = find({ id }, this.allParkings)
			let sessionConfig = this.initialParkingState.sessionConfig;
			if (editParking.parkingSessionConfigs) {
				sessionConfig = {
					perHourCost: editParking.parkingSessionConfigs[0]?.perHourCost,
					subscriptionFreeHours: editParking.parkingSessionConfigs[0]?.subscriptionFreeHours,
				}
			}

			this.parking = omit(
				['legacyAddress', 'comment', '__typename'],
				Object.assign(
					{},
					editParking,
					{
						legacyAddressNl: editParking.legacyAddress.nl,
						legacyAddressFr: editParking.legacyAddress.fr,
					},
					{
						commentNl: editParking.comment.nl,
						commentFr: editParking.comment.fr,
						commentEn: editParking.comment.en,
					},
					{
						hasSessionParking: editParking.parkingSessionConfigs.length > 0 ? true : false,
						sessionConfig: sessionConfig
					}
				)
			)
			this.selectParkingType(this.parking.parkingTypeName)
			this.selectKeyType(this.parking.keyType)
			this.formOpen = true
		},
		addParkingSpot() {
			this.parking.parkingSpots.push({ active: true })
		},
		removeParkingSpot(index) {
			this.parking.parkingSpots = this.parking.parkingSpots.filter((s, i) => i !== index)
			this.parkingSpotErrors[index] = {}
		},
		validateParkingSpots() {
			let ok = true

			this.parkingSpotErrors = {}

			const needClassicSpot = this.parking.capacityClassic && this.parking.capacityClassic > 0
			const needCargoSpot = this.parking.capacityCargo && this.parking.capacityCargo > 0
			let hasClassicSpot = false
			let hasCargoSpot = false

			this.parking.parkingSpots.forEach((s, i) => {
				this.parkingSpotErrors[i] = {}

				if (s.active) {
					if (s.isCargo) {
						hasCargoSpot = true

						if (!needCargoSpot) {
							this.parkingSpotErrors[i].amount =
								"You can't have a cargo price if you have no cargo spots"
							ok = false
						}
					} else {
						hasClassicSpot = true

						if (!needClassicSpot) {
							this.parkingSpotErrors[i].amount =
								"You can't have a classic price if you have no classic spots"
							ok = false
						}
					}
				}

				if (!s.amount) {
					this.parkingSpotErrors[i].amount = 'Price is Mandatory'
					ok = false
				}

				if (!s.name) {
					this.parkingSpotErrors[i].name = 'Description is Mandatory'
					ok = false
				}
			})

			if (needCargoSpot && !hasCargoSpot) {
				this.parking.parkingSpots.push({ name: 'Cargo', isCargo: true, active: true })

				const newSpotI = this.parking.parkingSpots.length - 1

				this.parkingSpotErrors[newSpotI] = { amount: 'You need at least one price for Cargo' }
				ok = false
			}

			if (needClassicSpot && !hasClassicSpot) {
				this.parking.parkingSpots.push({ name: 'Standard', isCargo: false, active: true })

				const newSpotI = this.parking.parkingSpots.length - 1

				this.parkingSpotErrors[newSpotI] = { amount: 'You need at least one price for Classic' }
				ok = false
			}

			return ok
		},
		addParkingSpotIfNotYet(name) {
			const isCargo = name === 'Cargo'
			const existing = this.parking.parkingSpots.find((s) => {
				return isCargo ? s.isCargo : !s.isCargo
			})

			if (!existing) {
				this.parking.parkingSpots.push({ name, isCargo, active: true })
			}
		},
		capacityChanged(name, nbr) {
			if (nbr && nbr > 0) {
				this.addParkingSpotIfNotYet(name)
			}
		},
		handleChangeParkingImagesUpload(e) {
			Vue.set(this.parking, 'imagesToUpload', Array.from(e.target.files))
		},
		removeParkingImage(index) {
			this.parking.images.splice(index, 1)
		},
		removeParkingImagesToUpload(index) {
			this.parking.imagesToUpload.splice(index, 1)
		}
		
	},
}
</script>

<style lang="scss">
@import '../../assets/scss/pages/adminParking';
@import '../../assets/scss/pages/admin';
.mx-datepicker {
	width: 100%;
}
.mx-input-append {
	width: 35px;
	right: 5px;
}
.session-field-container{
	margin-bottom:20px;
}
.image-container {
	display: flex;
	flex-wrap: wrap;
	gap: 2px;
	margin-top: 5px;
	margin-bottom: 20px;

	.image {
		display: flex;
		width: calc((100% - 16px)/5);
		justify-content: center;
		max-height: 100px;
		position: relative;
		img{
			width: 100%;
			height: auto;
		}
		.remove {
			position: absolute;
			top: 2px;
			right: 2px;
			width: 16px;
			cursor: pointer;
			background: #ffffffaa;
			padding: 2px;
			border-radius: 50%;
		}
	}
	.upload{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.button{
		align-self: center;
		text-align: center;
		padding: 10px 20px;
	}
}
</style>
