<template>
	<Accordion :title="$t('profile.title')" :isOpen="true">
		<div class="profile-fields">
			<div class="profile-field">
				<div class="profile-field-info">
					<h3 class="profile-field-title">
						{{ $t('profile.lastNameLabel') }}
					</h3>
					<p class="profile-field-value">
						{{ profile.lastName }}
					</p>
				</div>
			</div>
			<div class="profile-field">
				<div class="profile-field-info">
					<h3 class="profile-field-title">
						{{ $t('profile.firstNameLabel') }}
					</h3>
					<p class="profile-field-value">
						{{ profile.firstName }}
					</p>
				</div>
			</div>

			<div v-if="!isChild" class="profile-field">
				<div class="profile-field-info">
					<h3 class="profile-field-title">
						{{ $t('profile.emailLabel') }}
					</h3>
					<p class="profile-field-value">
						{{ profile.email }}
					</p>
				</div>
			</div>

			<div v-if="isChild" class="profile-field">
				<div class="profile-field-info">
					<h3 class="profile-field-title">
						{{ $t('profile.childEmailLabel') }}
					</h3>
					<p class="profile-field-value">
						{{  profile.childEmail ? profile.childEmail : '-' }}
					</p>
				</div>
			</div>
			<div v-if="!isChild" class="profile-field">
				<div class="profile-field-info">
					<h3 class="profile-field-title">
						{{ $t('profile.phoneLabel') }}
					</h3>
					<p class="profile-field-value">
						{{ profile.phoneNumber }}
					</p>
				</div>
			</div>
			<div v-if="!isChild" class="profile-field">
				<div class="profile-field-info">
					<h3 class="profile-field-title">
						{{ $t('profile.languageLabel') }}
					</h3>
					<p class="profile-field-value">
						{{ getLanguage }}
					</p>
				</div>
			</div>
			<div class="profile-field">
				<div class="profile-field-info">
					<h3 class="profile-field-title">
						{{ $t('profile.mobibLabelView') }}
					</h3>
					<p class="profile-field-value">
						{{ profile.mobib ? profile.mobib : '-' }}
					</p>
				</div>
			</div>
			<!-- <div class="profile-field">
			<form
				action
				class="profile-updatePayment"
				data-vv-scope="update-payment-method-form"
				@submit.prevent="submitUpdatePaymentMethod"
			>
				<div class="profile-field-info">
					<h3 class="profile-field-title">
						{{ $t('profile.currentPaymentMethod') }}
					</h3>
					<p class="profile-field-value">
						{{ displayPaymentMethod($t(`profile.paymentMethods.${profile.paymentMethod}`)) }}
						
						 <button
							v-if="!isAdminEdit && profile.existsInStripe && !profile.existsInMollie"
							class="button profile-updatePayment-buttonLink"
							type="submit"
						>
							{{ $t('profile.updatePaymentMethod') }}
						</button> 
						<router-link v-if="!isAdminEdit && profile.existsInMollie" to="/mollie-billing" class="button profile-updatePayment-buttonLink">
							{{ $t('profile.updatePaymentMethod') }}
						</router-link>
					</p>
				</div>
			</form>
		</div> -->
			<!-- <div v-if="!profile.mobib && !isAdminEdit" class="profile-form-block">
			<div
				:class="{ active: mobibFormOpen }"
				class="button blue activatable profile-action-button"
				tabindex="0"
				@mousedown="toggleMobibForm($event)"
				@keyup.enter="toggleMobibForm($event)"
			>
				{{ $t('profile.addMobibLabel') }}
			</div>
			<form
				:class="{ closed: !mobibFormOpen }"
				class="profile-form"
				@submit.prevent="doUpdateMobib"
			>
				<Loading v-if="mobibLoading" />
				<MobibInput
					:mobib.sync="profileLocal.mobib"
					:message="$t('profile.mobibInfo')"
					:mobibPresent="Boolean(profile.mobib)"
					errorTag="mobib"
					class="profile-mobib-box"
				/>
				<div v-if="errors.has('mobib')" class="input-error">
					{{ errors.first('mobib') }}
				</div>
				<button
					:disabled="errors.any() || !profileLocal.mobib"
					:class="{ disabled: errors.any() || !profileLocal.mobib }"
					class="button blue"
				>
					{{ $t('general.saveLabel') }}
				</button>
			</form>
		</div> -->

			<!-- <div v-if="!isChild" class="profile-field">
			<h3 v-if="profile.newsletter" class="profile-field-title">
				{{ $t('profile.newsletterStatusYes') }}
			</h3>
			<h3 v-else class="profile-field-title">
				{{ $t('profile.newsletterStatusNo') }}
			</h3>
		</div> -->
			<div
				class="profile-action"
				@mousedown="$emit('toggleEditProfile')"
				@keyup.enter="$emit('toggleEditProfile')"
			>
				{{ $t('profile.editProfileLabel') }}
			</div>
		</div>
	</Accordion>
</template>

<script>
import { mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import MobibInput from '@/components/form/MobibInput.vue'
import { languages } from '@/config'
import Accordion from '@/components/Accordion.vue'

export default {
	name: 'ProfileView',
	components: {
		Loading,
		MobibInput,
		Accordion,
	},
	props: {
		profile: {
			type: Object,
			default: () => ({}),
		},
		mobibLoading: {
			type: Boolean,
			default: false,
		},
		isChild: {
			type: Boolean,
			default: false,
		},
		isAdminEdit: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mobibFormOpen: false,
			mobib: this.profile.mobib, // From props
			profileLocal: {
				mobib: '',
			},
		}
	},
	computed: {
		getLanguage() {
			return this.profile.language
				? languages.find((l) => l.key === this.profile.language).value
				: ''
		},
	},
	created() {},
	mounted() {
		console.log('profile', this.profile)
	},
	methods: {
		...mapActions('profile', {
			updatePaymentMethod: 'updatePaymentMethod',
		}),
		toggleMobibForm(e) {
			e.preventDefault()
			this.mobibFormOpen = !this.mobibFormOpen
		},
		doUpdateMobib() {
			this.$emit('add', this.profileLocal.mobib)
		},
		async submitUpdatePaymentMethod() {
			const url = await this.updatePaymentMethod()

			window.location.href = url
		},

		displayPaymentMethod(value) {
			const knowedPaymentMethods = ['card', 'bancontact', 'none']
			const paymentMethod = this.profile.paymentMethod

			if (knowedPaymentMethods.includes(paymentMethod)) {
				return value
			} else {
				return paymentMethod
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
</style>
