import APIClient from '../api/apiClient'

const PostcodePricesService = {
	// Service to get all postcode prices
	getPostcodePrices: (params) => {
		return APIClient.get(`/postcode-prices/all-postcode-prices`, { params })
	},

	// Service to get postcode price by ID
	getPostcodePriceById: (postcodepriceid) => {
		return APIClient.get(`/postcode-prices/postcode-price-operations/${postcodepriceid}`)
	},

	// Service to upsert postcode price
	upsertPostcodePrice: (payload) => {
		return APIClient.post(`/postcode-prices/postcode-price-operations`, payload)
	}
}

export default PostcodePricesService