// session.js

import graphqlClient from '../../../api/db'
import gql from 'graphql-tag'
import { extractGraphqlError } from '@/helpers'
import ParkingsService from '../../../services/parkings'
import SessionService from '../../../services/sessions'
import GeneralService from '../../../services/general'

// Initial state
const initialState = () => ({
	all: [],
	total: 0,
	selected: {},
	isLoading: false,
	error: null,
})

const state = initialState()

// GraphQL Queries
const getAllSessionsQuery = gql`
	query AllSessions($limit: Int, $offset: Int, $isActive: Boolean, $search: String) {
		allSessions(limit: $limit, offset: $offset, isActive: $isActive, search: $search) {
			sessions {
				bikes
				duration
				endTime
				createdAt
				id
				active
				parking {
					code
					id
				}
				paymentMethod
				sessionId
				startTime
				canceledAt
				user {
					email
					firstName
					id
					phoneNumber
					lastName
					language
					addresses {
						id
						addressType
						streetName
						houseNumber
						busNumber
						city
						postalCode
						realAddress
					}
					legacyAddress
				}
				totalAmountPaid
				sessionQRCodes {
					id
					expiresIn
					qrCode
					qrType
					usage
					used
					usedAt
					userSessionId
					createdAt
				}
			}
			total
		}
		serverTime
	}
`

const getSessionByIdQuery = gql`
	query SessionById($id: Int) {
		sessionById(id: $id) {
				bikes
				duration
				endTime
				createdAt
				id
				active
				parking {
					code
					id
				}
				paymentMethod
				sessionId
				startTime
				canceledAt
				userId
				user {
					email
					firstName
					id
					phoneNumber
					lastName
					language
					mobib
					bankAccount
					addresses {
						id
						addressType
						streetName
						houseNumber
						busNumber
						city
						postalCode
						realAddress
					}
					legacyAddress
				}
				totalAmountPaid
				sessionQRCodes {
					id
					expiresIn
					qrCode
					qrType
					usage
					used
					usedAt
					userSessionId
					createdAt
				}
				qrScanLogs{
					id
					sessionQrCodeId
					status
					description
					payload
					scannedAt
					createdAt
					scannerPayload
				}
		}
		serverTime
	}
`

// Actions
const actions = {
	async getSessions({ commit }, { limit, offset, isActive, search }) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.query({
			// 	query: getAllSessionsQuery,
			// 	variables: { limit, offset, isActive },
			// 	fetchPolicy: 'no-cache',
			// })
			// const sessions = response.data.allSessions
			const params = {
				offset,
				limit,
				// isActive,
				sortkey : 'id',
				sortorder : 'DESC',
			}
			const body = {
				isActive: String(isActive),
				search,
			}
			const [response, serverTime] = await Promise.all([
				SessionService.getAllSessions(params, body),
				GeneralService.getServerTime(),
			])
			const sessions = response.data.allSessions
			// const response = await graphqlClient.query({
			// 	query: getAllSessionsQuery,
			// 	variables: { limit, offset, isActive, search },
			// 	fetchPolicy: 'no-cache',
			// })
			// const sessions = response.data.allSessions?.sessions
			const total = response.data.total
			commit('setSessionsTotal', total)
			commit('setSessions', sessions)
			commit('setServerTime', serverTime, { root: true })
		} catch (e) {
			logger.error('Problem retrieving sessions', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async getSessionById({ commit }, id) {
		commit('setLoading', true)

		try {
			const [response, serverTime] = await Promise.all([
				SessionService.getSessionById(id),
				GeneralService.getServerTime(),
			])
			const session = response.data
			commit('setSessionSelected', session || {})
			commit('setServerTime', serverTime, { root: true })
		} catch (e) {
			logger.error('Problem retrieving sessions', e)
		} finally {
			commit('setLoading', false)
		}
	},
}

// Mutations
const mutations = {
	resetState(state) {
		Object.assign(state, initialState())
	},
	setSessions(state, sessions) {
		state.all = sessions
	},
	setSessionsTotal(state, total) {
		state.total = total
	},
	setSessionSelected(state, session) {
		state.selected = session
	},
	setSessionId(state, sessionId) {
		localStorage.setItem('sessionId', sessionId)
		state.isLoggedIn = true
	},
	setLoading(state, isLoading) {
		state.isLoading = isLoading
	},
	setError(state, error) {
		state.error = error
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
}
